import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//路由导航守卫
import './permission'

import './styles/reset.css' // 样式重置表
import './styles/global.css' // 全局样式表

// 全局方法
import globalFun from './utils'

Vue.config.productionTip = false
Vue.prototype.$globalFun = globalFun

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
