import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/index.vue";
import EmptyLayout from "@/layout/empty";

Vue.use(VueRouter);

const constantRoutes = [
  {
    path: "/",
    component: Layout,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
  // {
  //   path: "/timeDetail",
  //   component: Layout,
  //   children: [
  //     {
  //       path: "/timeDetail",
  //       name: "timeDetail",
  //       component: () => import("@/views/consult/timeDetail"),
  //     },
  //   ],
  // },
];
// ..路由
const asyncRoutes = [
  // visit
  {
    path: "/reserve",
    component: Layout,
    meta: {
      title: "预约管理",
      icon: require("@/assets/images/icon.png"),
      id: 1,
    },
    redirect: "/reserve",
    children: [
      {
        path: "",
        name: "reserve",
        meta: {
          title: "",
          id: 18,
          parent_id: 1,
          sidebar: false,
          activeMenu: "/reserve",
        },
        component: () => import("@/views/reserve/index.vue"),
      },
      {
        path: "/reserve/detail",
        name: "reserveDetail",
        meta: {
          title: "详情",
          id: 19,
          parent_id: 1,
          sidebar: false,
          activeMenu: "/reserve",
        },
        component: () => import("@/views/reserve/detail.vue"),
      },
    ],
  },
  {
    path: "/visit",
    name: "visit",
    component: Layout,
    redirect: "/visit/list",
    subset: true,
    meta: {
      title: "就诊管理",
      icon: require("@/assets/images/icon1.png"),
      id: 2,
    },
    children: [
      {
        path: "list",
        name: "list",
        redirect: "/visit/list",
        component: EmptyLayout,
        meta: {
          title: "就诊列表",
          id: 21,
          parent_id: 2,
        },
        children: [
          {
            path: "/visit/list",
            name: "visitIndex",
            component: () => import("@/views/visit/index.vue"),
            meta: {
              id: 53,
              parent_id: 21,
              sidebar: false,
              activeMenu: "/visit/list",
            },
          },
          {
            path: "/visit/detail",
            name: "visitDetail",
            component: () => import("@/views/visit/detail.vue"),
            meta: {
              id: 22,
              parent_id: 21,
              sidebar: false,
              activeMenu: "/visit/list",
            },
          },
        ],
      },
      {
        path: "feedback",
        name: "feedback",
        redirect: "/visit/feedback",
        component: EmptyLayout,
        meta: {
          title: "反馈列表",
          id: 23,
          parent_id: 2,
        },
        children: [
          {
            //列表
            path: "",
            name: "feedbackIndex",
            component: () => import("@/views/feedback/index.vue"),
            meta: {
              id: 54,
              parent_id: 23,
              sidebar: false,
              activeMenu: "/visit/feedback",
            },
          },
          {
            // 详情
            path: "/visit/feedback/detail",
            name: "feedbackDetail",
            component: () => import("@/views/feedback/detail.vue"),
            meta: {
              id: 24,
              parent_id: 23,
              sidebar: false,
              activeMenu: "/visit/feedback",
            },
          },
        ],
      },
    ],
  },
  {
    // moban
    path: "/patient",
    component: Layout,
    redirect: "/patient",
    subset: true,
    meta: {
      title: "患者管理",
      icon: require("@/assets/images/icon2.png"),
      id: 3,
    },
    children: [
      {
        path: "",
        redirect: "/patient",
        component: EmptyLayout,
        meta: {
          title: "患者管理",
          id: 27,
          parent_id: 3,
          sidebar: false
        },
        children: [
          {
            path: "",
            name: "patient",
            component: () => import("@/views/patient/index.vue"),
            meta: {
              id: 55,
              parent_id: 27,
              sidebar: false,
              activeMenu: "/patient",
            },
          },
          {
            path: "/patient/detail",
            name: "patientDetail",
            component: () => import("@/views/patient/detail.vue"),
            meta: {
              title: "详情",
              id: 28,
              parent_id: 27,
              sidebar: false,
              activeMenu: "/patient",
            },
          },
          {
            path: "/patient/historydetail",
            name: "patientHisDetail",
            component: () => import("@/views/patient/historyDetail.vue"),
            meta: {
              title: "历史详情",
              id: 28,
              parent_id: 27,
              sidebar: false,
              activeMenu: "/patient",
            },
          },
        ],
      },
    ],
  },
  {
    //
    path: "/doctor",
    name: "doctor",
    component: Layout,
    redirect: "/doctor/department",
    subset: true,
    meta: {
      title: "医生管理",
      icon: require("@/assets/images/icon3.png"),
      id: 4,
    },
    children: [
      {
        path: "department",
        name: "department",
        redirect: "/doctor/department",
        component: EmptyLayout,
        meta: {
          title: "科室列表",
          id: 29,
          parent_id: 4,
        },
        children: [
          {
            path: "/doctor/department",
            name: "departmentIndex",
            component: () => import("@/views/department/index.vue"),
            meta: {
              id: 56,
              parent_id: 29,
              sidebar: false,
              activeMenu: "/doctor/department",
            },
          },
        ],
      },
      {
        path: "doctorList",
        name: "doctorList",
        redirect: "/doctor/doctorList",
        component: EmptyLayout,
        meta: {
          title: "医生列表",
          id: 34,
          parent_id: 4,
        },
        children: [
          {
            path: "/doctor/doctorList",
            name: "doctorListIndex",
            component: () => import("@/views/doctorList/index.vue"),
            meta: {
              id: 57,
              parent_id: 34,
              sidebar: false,
              activeMenu: "/doctor/doctorList",
            },
          },
        ],
      },
    ],
  },
  {
    //
    path: "/rostering",
    name: "rostering",
    component: Layout,
    redirect: "/rostering/rosteringList",
    subset: true,
    meta: {
      title: "排班管理",
      icon: require("@/assets/images/icon4.png"),
      id: 5,
    },
    children: [
      {
        path: "rosteringList",
        // name: "rosteringList", 可去
        redirect: "/rostering/rosteringList",
        component: EmptyLayout,
        meta: {
          title: "排班列表",
          id: 39,
          parent_id: 5,
        },
        children: [
          {
            path: "/rostering/rosteringList",
            name: "rosteringList",
            component: () => import("@/views/rostering/index.vue"),
            meta: {
              id: 58,
              parent_id: 39,
              sidebar: false,
              activeMenu: "/rostering/rosteringList",
            },
          },
        ],
      },
      {
        path: "shift",
        component: EmptyLayout,
        redirect: '/rostering/shift',
        meta: {
          title: "班次设置",
          id: 44,
          parent_id: 5,
        },
        children: [
          {
            path: "/rostering/shift",
            name: "shift",
            component: () => import("@/views/shift/index.vue"),
            meta: {
              id: 59,
              parent_id: 44,
              sidebar: false,
              activeMenu: "/rostering/shift",
            },
          },
        ],
      },
    ],
  },
  // {
  //     path: "/report",
  //     component: Layout,
  //     meta: {
  //       title: "统计报表",
  //       icon: require("@/assets/images/icon2.png"),
  //       id: 3,
  //     },
  //     redirect: "/report",
  //     children: [
  //       {
  //         path: "",
  //         name: "report",
  //         meta: {
  //           title: "统计报表",
  //           // id: 43,
  //           // parent_id: 3,
  //           sidebar: false,
  //           activeMenu: "/report",
  //         },
  //         component: () => import("@/views/report/index.vue"),
  //       },
  //     ],
  //   },

  {
    path: "/system",
    name: "system",
    component: Layout,
    subset: true,
    redirect: "/system/administrator",
    meta: {
      title: "系统管理",
      icon: require("@/assets/images/icon5.png"),
      id: 6,
    },
    children: [
      {
        path: "administrator",
        name: "administrator",
        redirect: "/system/administrator",
        component: EmptyLayout,
        meta: {
          title: "管理员管理",
          id: 12,
          parent_id: 6,
        },
        children: [
          {
            path: "/system/administrator",
            name: "administratorIndex",
            component: () => import("@/views/administrator/index.vue"),
            meta: {
              id: 52,
              parent_id: 12,
              sidebar: false,
              activeMenu: "/system/administrator",
            },
          },
        ],
      },
      {
        path: "role",
        name: "role",
        redirect: "/system/role",
        component: EmptyLayout,
        meta: {
          title: "角色管理",
          id: 7,
          parent_id: 6,
        },
        children: [
          {
            path: "/system/role",
            name: "roleIndex",
            component: () => import("@/views/role/index.vue"),
            meta: {
              id: 51,
              parent_id: 7,
              sidebar: false,
              activeMenu: "/system/role",
            },
          },
        ],
      },
      {
        path: "clinic",
        name: "clinic",
        redirect: "/system/clinic",
        component: EmptyLayout,
        meta: {
          title: "诊所信息",
          id: 49,
          parent_id: 6,
        },
        children: [
          {
            path: "/system/clinic",
            name: "clinicIndex",
            component: () => import("@/views/clinic/index.vue"),
            meta: {
              id: 50,
              parent_id: 49,
              sidebar: false,
              activeMenu: "/system/clinic",
            },
          },
        ],
      },
    ],
  },
];

// 解决路由在 push/replace 了相同地址报错的问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
export { asyncRoutes, constantRoutes };

export default router;
