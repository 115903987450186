import Router from "@/router";
import { accountLogin, accountLogout, accountRole } from "@/api/login"

const state = {
  info: localStorage.info, //用户信息
  //token
  token: localStorage.token,
};

const getters = {};

const actions = {
  //登录
  login({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        // const res = await api.login(data)
        // console.log('登录成功', res)
        console.log('login_data', data)
        const { res } = await accountLogin(data)

        commit("setUserData", res);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },
  // 退出登录 接口
  async Logout({ commit }, data) {
    console.log('logoutaction', data)
    if (!data) {
      await accountLogout()     
    }
    commit('menu/invalidRoutes', null, { root: true })  //退出登录调
    commit('removeUserData')
    Router.push({
        path: '/login'
    })
  },
  // 获取权限 => 动态路由
  getPermissions ({ commit }) {
    return new Promise(async resolve=> {
      const { res } = await accountRole()
      console.log('存vuex里账号的权限', res)
      resolve(res)
    })
  }
};

const mutations = {
  setUserData (state, data) {
    console.log('mutations', state, data)
    //
    localStorage.setItem('info', JSON.stringify(data.user))
    localStorage.setItem('token', data.token)
    state.info = data.user
    state.token = data.token
  },
  removeUserData(state) {
    console.log('loout mutation' ,state)
    // 退出登录删
    localStorage.removeItem('info')
    localStorage.removeItem('token')
    state.info = ''
    state.token = ''
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
